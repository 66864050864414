import { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../../bootstrap/component/layout/footer-4";
import Header from "../../bootstrap/component/layout/header-5";



const title = "Olvidaste la contraseña";
const btnText = "Solicitar";


const ForgetPass = () => {
    return (
        <Fragment>
            <Header />
            <section className="banner-section">
            <div>
                <div className="section-wrapper">
            <div className="login-section padding-tb section-bg">
                <div className="container">
                    <div className="account-wrapper">
                        <h3 className="title">{title}</h3>
                        <form className="account-form">
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="email"
                                    placeholder="Correo electrónico *"
                                />
                            </div>
                            <div className="form-group text-center">
                                <button className="d-block lab-btn"><span>{btnText}</span></button>
                            </div>
                        </form>
                        <div className="account-bottom">
                            <span className="d-block cate pt-10">Tienes una cuenta?  <Link to="/login">Iniciar sesión</Link></span>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
        </section>
            <Footer />
        </Fragment>
    );
}
 
export default ForgetPass;