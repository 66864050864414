
/**
 * Method to save the user object in the locla storage.
 */
export const saveStorage = (userObject) => {
  const encodeObject = window.btoa(JSON.stringify(userObject));
  localStorage.setItem('creativeSession', encodeObject);
};

/**
 * Method to get the user object.
 */
export const getStorage = () => {
  const encodeObject = localStorage.getItem('creativeSession') ? window.atob(localStorage.getItem('creativeSession')) : null;
  const userObject = JSON.parse(encodeObject);
  return userObject;
};
/**
 * Method to validate if the user is authenticated.
 */
export const isAuth = () => !!localStorage.getItem('creativeSession');

/**
 * Method to get the dead line date.
 */
export const getDeadLineDate = () => {
  const encodeObject = localStorage.getItem('creativeSession') ? window.atob(localStorage.getItem('creativeSession')) : null;
  const userObject = JSON.parse(encodeObject);
  if (userObject !== null) {
    const { deadLineDate } = userObject;
    return deadLineDate;
  }
  return null;
};

/**
 * Method to get the dead line date.
 */
export const getRol = () => {
  const encodeObject = localStorage.getItem('creativeSession') ? window.atob(localStorage.getItem('creativeSession')) : null;
  if(encodeObject !== undefined){
    const userObject = JSON.parse(encodeObject);
    if (userObject !== null) {
      const { rol } = userObject;
      return rol;
    }
  }
  return null;
};
