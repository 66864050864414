import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import axios from 'axios';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { setListSubject } from '../actions/subjectActions';


const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

/**
 * Function to delete the pricing plans
 * @param {*} props
 * @returns
 */
function DeleteSubjectDialog(props) {
  const {
    openStatus, onClosed, classes, rowData
  } = props;
   /**
  * const subject list
  */
  const listSubject = useSelector(state => state.subject, shallowEqual);
  const endpoint = 'http://127.0.0.1:8000/api/subject/';
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const dispatch = useDispatch();

  const handleCloseError = (event, reason) => {
    setOpen(false);
  };

  const handleClose = (event, reason) => {
    setOpenError(false);
  };


  /**
 * Method to delete the register in the table
 * @param {data} data
 */
  const handleDelete = (index) => {
    const newListSubject = [...listSubject];
    newListSubject.splice(index, 1);
    dispatch(setListSubject(newListSubject));
  };

  /**
   * Method to close the dialog and show the success message
   * @param {data} data
   */
  const handleOK = (data) => {
    for (let cont = 0; cont < listSubject.length; cont += 1) {
      if (listSubject[cont].id === data.id) {
        handleDelete(cont);
        break;
      }
    }
    onClosed();
    setOpen(true);
  };

  const handleError = () => {
    setErrorMessage('La acción no se pudo realizar.');      
    onClosed();
    setOpenError(true);
  };

  /**
   * Method to delete the register and create que request
   * @param {*} formData
   */
  const onDelete = async() => {
    const id = rowData[0];
    await axios.delete(endpoint+id).then((response) => {
      handleOK(response.data);
    })
    .catch((e) => {
      handleError(e);
    });
  };

  return (
    <Grid container justify="center" direction="column">
      <Dialog
        open={openStatus}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClosed}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title"> Confirmacion </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Esta seguro que desea eliminar la materia {rowData[1]} 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button id="button_close" onClick={onClosed}> Cerrar </Button>
          <Button id="button_agree_delete_subject" variant="contained" onClick={onDelete} style={{backgroundColor: "#68dcb9", color: "#FFFFFF"}}>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose} >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="success" sx={{ width: '100%' }} center>
          La acción se ejecutó de manera correcta.
        </Alert>
      </Snackbar>
    </Grid>
  );
}

DeleteSubjectDialog.propTypes = {
  /** status of delete dialog */
  openStatus: PropTypes.bool.isRequired,
  /** function of delete dialog */
  onClosed: PropTypes.func.isRequired,
  /** Array of pricing plans */
  rowData: PropTypes.array.isRequired,
};

export default DeleteSubjectDialog;
