import { Component, useState, useEffect } from "react";
import img1 from "../../../assets/images/course/creativekids/p01.jpg";
import img2 from "../../../assets/images/course/creativekids/p02.jpg";
import img3 from "../../../assets/images/course/creativekids/p03.jpg";
import img4 from "../../../assets/images/course/creativekids/p04.jpg";
import img5 from "../../../assets/images/course/creativekids/p05.jpg";
import img6 from "../../../assets/images/course/creativekids/p06.jpg";
import { Link } from "react-router-dom";
import Rating from "../sidebar/rating";

const title = "Conocenos más";


const CourseData = [
    {
        imgUrl: img1,
        imgAlt: 'course rajibraj91 rajibraj',
        cate: 'Metodologías',
        title: 'Metodología Montessori ',
        authorName: 'El Método Montessori favorece cada día las experiencias naturales, sensoriales y culturales. El niño deja de tener un rol pasivo y pasa a ser el protagonista activo del propio proceso de enseñanza-aprendizaje.' +
        '\n El niño desarrolla la curiosidad, puesto que este método educativo considera la curiosidad como elemento clave en el aprendizaje, aunque también reconoce conceptos necesarios para aprender.' +
        '\n•	Fomenta el movimiento, ya que tiene en cuenta la necesidad de los niños de estar casi en constante movimiento.' +
        '\n•	Favorece la creatividad, la imaginación y el pensamiento crítico y apuesta por el aprendizaje personalizado respetando el ritmo de cada niño.' +
        '\n•	Mejora las habilidades sociales y la autonomía de los niños, además de ayudar a que tengan autodisciplina.',
        id: 1,
    },
    {
        imgUrl: img2,
        imgAlt: 'course rajibraj91 rajibraj',
        cate: 'Metodologías',
        title: 'Metodología  STEAM',
        authorName: '( STEAM Education) es una nueva forma práctica de enseñar Science, Technology, Engineering y Mathematics como un todo desde edades tempranas'+
        '\nEstas actividades y experimentos ayudan a los niños a desarrollar habilidades de pensamiento lógico para resolver problemas, Además de:'+
        '\n•	Aumentar su capacidad para la resolución de problemas de manera creativa'+
        '\n•	Mejorar su autoestima'+
        '\n•	Impulsar sus capacidades comunicativas'+
        '\n•	Aprender mediante la experimentación en primera persona'+
        '\n•	Retener más fácilmente los conceptos aprendidos'+
        '\n•	Desarrollar su imaginación y sus ganas de crear cosas nuevas',
        id: 2,
    },
    {
        imgUrl: img3,
        imgAlt: 'course rajibraj91 rajibraj',
        cate: 'Directora',
        title: 'Dra. Nadia Flor Romero',
        authorName: 'Profesora Parvularia'+
        '\n•	Master en problemas de aprendizaje  ISEP España'+
        '\n•	Master en Metodología Montessori Udemy México'+
        '\n•	Master en Psicología Gestalt Udemy México',
        id: 1,
    },
    {
        imgUrl: img4,
        imgAlt: 'course rajibraj91 rajibraj',
        cate: 'Equipo pedagógico',
        title: 'Coordinadora general ',
        authorName: 'María  Chapi' +  
        'A cargo del área de Math, development of Logical things, and Musical appreciation.'+ 
        '\n•	Título: Licenciada en ciencias de la educación mención profesora Parvularia'+
        '\n•	Universidad Central del Ecuador'+
        '\n•	Inglés nivel:  A2'+
        '\nCursos: '+
        '\n- Descubriendo Montessori'+
        '\n- La trascendencia del educador durante la primera infancia',
        id: 1,
    },
    {
        imgUrl: img5,
        imgAlt: 'course rajibraj91 rajibraj',
        cate: 'Equipo pedagógico',
        title: 'Coordinadora Pedagógica',
        authorName: 'Paola Guanoquiza'+
        'A cargo del área de: Language grammar, Language phonics and Reading animation'+
        '\n•	Título: Licenciada en ciencias de la educación mención Educación Infantil'+ 
        '\n•	Universidad ESPE'+
        '\n•	nivel B2 de inglés en el marco europeo'+
        '\nCursos: '+
        '\n- Descubriendo Montessori'+
        '\n- Introducción al currículo de educación Inicial y metodologías de trabajo',
        id: 2,
    },
    {
        imgUrl: img6,
        imgAlt: 'course rajibraj91 rajibraj',
        cate: 'Equipo pedagógico',
        title: 'Coordinadora del área de Science y Steam',
        authorName: 'Nombre Jesenia Herrera '+
        'A cargo del área de: Science, Steam, Sport.- yoga '+
        '\n•	Título Tecnología universitaria en Desarrollo del Talento infantil'+
        '\n•	Instituto: Instituto Universitario Cordillera'+ 
        '\n•	Nivel de Inglés: B1 '+
        '\nCursos: '+
        '\n- Descubriendo Montessori '+
        '\n- Educación emocional positiva y desarrollo integral',
        id: 3,
    }
]


function CourseFour() {

    const [items, setItems] = useState([]);
    const filterItem = (categItem) => {
        const updateItems = CourseData.filter((curElem) => {
            return curElem.cate === categItem;
        });
        setItems(updateItems);
    }
    useEffect(() => {
        const updateItems = CourseData.filter((curElem) => {
            return curElem.cate === 'Metodologías';
        });
        setItems(updateItems);
      }, []);

    return (
        <div className="course-section style-3 padding-tb">
            <div className="course-shape one"><img src="../../../assets/images/shape-img/icon/01.png" alt="" /></div>
            <div className="course-shape two"><img src="../../../assets/images/shape-img/icon/02.png" alt="" /></div>
            <div className="container">
                <div className="section-header">
                    <h2 className="title">{title}</h2>
                    <div className="course-filter-group">
                        <ul className="lab-ul">
                            <li onClick={() => filterItem('Metodologías') }>Metodologías</li>
                            <li onClick={() => filterItem('Directora') }>Directora</li>
                            <li onClick={() => filterItem('Equipo pedagógico') }>Equipo pedagógico</li> 
                        </ul>
                    </div>
                </div>
                <div className="section-wrapper">
                    <div className="row g-4 justify-content-center row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-1 course-filter">
                        { items.map((elem) => {
                            const { id, imgUrl, imgAlt, cate, title, authorName } = elem;
                            return (
                                <div className="col" key={id}>
                                    <div className="course-item style-4">
                                        <div className="course-inner">
                                            <div className="course-thumb">
                                                <img src={imgUrl} alt={imgAlt} />
                                                <div className="course-category">
                                                    <div className="course-cate">
                                                        <a href="#">{cate}</a>
                                                    </div>
                                                    <div className="course-reiew">
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="course-content">
                                                <h5 class="notranslate">{title}</h5>
                                                <div className="course-footer">
                                                    <div className="course-author">
                                                        <span>
                                                        {authorName.split('\n').map((linea, index) => (
                                                            <div key={index} class="notranslate">{linea}</div>
                                                        ))}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default CourseFour;

