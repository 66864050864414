
/**
 * Method to clear the user object in the local storage.
 */
export const clearStorage = () => {
  localStorage.removeItem('creativeSession');
};

/**
 * Method to do the logout and clean the authenticated user information in redux
 */
export const logout = (history) => {
  const clearAuthenticatedUserData = () => {
    clearStorage();
    // redirect to login
    history.push("/");
  };

  return clearAuthenticatedUserData();
};
