/** Reducer for List certificate request */

import { fromJS } from 'immutable';
import { SET_LIST_PARENT } from '../actions/parentConst';

export const initialState = {
  listParent: []
};

const initialImmutableState = fromJS(initialState);

const parent = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_LIST_PARENT:
      state = initialState;
      return state.listParent.concat(action.parent);
    default:
      return state;
  }
};

export default parent;
