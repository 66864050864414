import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import axios from 'axios';
import ActionDialogSubject from './ActionDialogSubject';


function ShowSubject (props)  {
  const endpoint = 'http://127.0.0.1:8000/api';
  const [course, setCourse] = useState([]);

  const listSubject = useSelector(state => state.subject);
    const [rowDataState, setRowDataState] = useState([]);
    const [statusButton, setStatusButton] = React.useState(false);

      const [tableColumns, setTableColumns] = useState([]);
      const [tableList, setTableList] = useState([]);
      
      const columns = [
        {
          name: 'id',
          options: {
            filter: false,
            display: false,
          }
        },
        {
          label: 'Nombre',
          name: 'name',
          options: {
            filter: true,
          }
        },
        {
          label: 'Descripción',
          name: 'description',
          options: {
            filter: true,
          }
        },
        {
          label: 'Curso',
          name: 'course_id',
          options: {
            filter: true,
            customBodyRender: (value) => {
              const item = value;
              let nameItem = '';
              course.map((option) => {
                if (option.id === item) {
                  nameItem = option.name;
                }
                return nameItem;
              });
              return nameItem;
            },
          }
        },
        {
          name: 'course_id',
          options: {
            filter: true,
            display: false,
          }
        },
        {
            label: 'Estado',
            name: 'status',
            options: {
              filter: true,
              customBodyRender: (value) => {
                if(value === 'Active'){
                  return 'Activo';
                }
                  return 'Removido';
                }
              }
          },
      ];

     /**
 * Method to set the documents list and tabe columns
 */
  useEffect(() => {
    if (listSubject && listSubject.length >= 0) {
      setTableList(listSubject);
      if (tableColumns.length === 0 && course.length !== 0  && course.length !== undefined)  {
        setTableColumns(columns);
      }
    }
  }, [listSubject, course]);
 
    /**
  * Method to set the list pricing plans
  */
    const getAllActiveCourse = async() => {
      const response = await axios.get(endpoint+'/courses');
      setCourse(response.data);
    };
  
    /**
     * Retrieve pricing plan list
     */
    useEffect(() => {
      getAllActiveCourse();  
    }, []);
    
  
      /**
       * Method to recoer the specifid values of the row
       * @param {rowData} rowData
       */
      const handleRowData = (rowData) => {
        const rowDataTemp = [];
        rowDataTemp.length = 0;
        rowDataTemp.push(rowData[0]);
        rowDataTemp.push(rowData[1]);
        rowDataTemp.push(rowData[2]);
        rowDataTemp.push(rowData[3]);
        rowDataTemp.push(rowData[4]);
        rowDataTemp.push(rowData[5]);  
        setRowDataState(rowDataTemp);   
      };
    
      const handleCloseButton = () => {
        setStatusButton(false);
      };
    
      const options = {
        selectableRowsHideCheckboxes: true,
        selectableRows: 'single',
        filterType: 'dropdown',
        responsive: 'standard',
        print: true,
        selectableRowsOnClick: false,
        rowsPerPage: 10,
        downloadOptions: {
          filename: 'lp',
          filterOptions: {
            useDisplayedRowsOnly: true,
          }
        },
        onDownload: (buildHead, buildBody, columnsData, data) => {
          const val = '\uFEFF' + `${buildHead(columnsData)}${buildBody(data)}`.trim();
          return val;
        },
        onRowClick: (rowData) => {          
          handleRowData(rowData);
          setStatusButton(true);
        }
      };
    
      return (
        <div>
        <div>
          <MUIDataTable
            title='Materias'
            data={tableList}
            columns={tableColumns}
            options={options}
          />
          <ActionDialogSubject openStatus={statusButton} onClosed= {handleCloseButton} rowData={rowDataState} course={course}/>
        </div>
        </div>
      );
}

ShowSubject.propTypes = {
  dataCourse: PropTypes.string,
};

ShowSubject.defaultProps = {
  dataCourse: "true"
};

export default ShowSubject