import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from '../../../assets/images/creativeKids/logo.png';


const HeaderFive = () => {
    const [menuToggle, setMenuToggle] = useState(false);
	const [socialToggle, setSocialToggle] = useState(false);
	const [headerFiexd, setHeaderFiexd] = useState(false);

	window.addEventListener("scroll", () => {
		if (window.scrollY > 200) {
			setHeaderFiexd(true);
		} else {
			setHeaderFiexd(false);
		}
	});
    
    return (
        <header className={`header-section style-3 style-5 ${headerFiexd ? "header-fixed fadeInUp" : ""}`}>
            <div className={`header-top d-md-none ${socialToggle ? "open" : ""}`}>
                <div className="container">
                    <div className="header-top-area">
                        <Link to="/login">Log In</Link>
                    </div>
                </div>
            </div>
            <div className="header-bottom">
                <div className="container">
                    <div className="header-wrapper">
                        <div className="logo-search-acte">
                            <div className="logo">
                                <Link to="/"><img src={logo} alt="logo" width="300vh"/></Link>
                            </div>
                        </div>
                        <div className="menu-area">
                            
                        {/* <Link to="/login" className="lab-btn d-none d-md-block"><span>Agendar clase demostrativa</span></Link>*/}
                            <div className="menu">
                                <ul className={`lab-ul ${menuToggle ? "active" : ""}`}>
                                    <li className="menu-item-has-children">
                                        <a href="#banner" role="button" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Inicio</a>
                                    </li>
                                    <li className="menu-item-has-children">
                                        <a href="#services" role="button" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Servicios</a>
                                    </li>
                                    <li className="menu-item-has-children">
                                        <a href="#aboutUs" role="button" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Conocenos</a>
                                    </li>
                                    <li className="menu-item-has-children">
                                        <a href="#contactUs" role="button" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Contáctenos</a>
                                    </li>
                                </ul>
                            </div>
                            { /* <Link to="/login" className="lab-btn d-none d-md-block"><span>Iniciar sesión</span></Link>*/}
                        
                            <div className="ellepsis-bar d-md-none" onClick={() => setSocialToggle(!socialToggle)}>
                                <i className="icofont-info-square"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
 
export default HeaderFive;