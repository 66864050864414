import React, { useState, Fragment, useEffect } from "react";
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Link } from "react-router-dom";
import Footer from "../../bootstrap/component/layout/footer-4";
import Header from "../../bootstrap/component/layout/header-5";
import Fab from '@material-ui/core/Fab';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import styles from '../landing/styles/home.module.css';
import Snackbar from '@mui/material/Snackbar';
import { useForm } from 'react-hook-form';
import MuiAlert from '@mui/material/Alert';
import { saveStorage, isAuth, getRol } from '../../utils/authenticatedUser';
import TextField from '@material-ui/core/TextField';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

function Login(props) { 
 // Redirect to whatsapp window
    const handleWhatsappPress = async () => {
        const URL = "https://wa.me/+593998590707?text=Hola gracias por comunicarte con Creative Kids. Con mucho gusto te ayudamos. ¿Cuál es tu duda?";
        window.open(URL);
    };

    const history = useHistory();
    const { register, handleSubmit, errors } = useForm();  
    const title = "Iniciar Sesión";
    const endpoint = 'http://127.0.0.1:8000/api/auth/login';
    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [open, setOpen] = useState(false);

    const handleCloseError = (event, reason) => {
        setOpen(false);
    };

    const handleClose = (event, reason) => {
        setOpenError(false);
    };

    useEffect(() => {
        if (isAuth()) {
            if(getRol() === 'teacher'){
                history.push('/publicTeachers');
            }else if(getRol() === 'parent'){
                history.push('/publicParents');
            }
        }
    }, []);

    /**
     * Method to close the dialog and show the success message
     * @param {data} data
     */
    const handleOK = (data) => {
        saveStorage(data);
        if(getRol() === 'teacher'){
            history.push('/publicTeachers');
        }else if(getRol() === 'parent'){
            history.push('/publicParents');
        } else{
            history.push('/');
        }
    };

    const handleError = () => {
        setErrorMessage('La acción no se pudo realizar.');      
        setOpenError(true);
    };


    /**
     * Method to delete the register and create que request
     * @param {*} formData
     */
    const onSubmit = async(data) => {
        const requestBody = {
            email: data.email,
            password: data.password
          };
        await axios.post(endpoint, requestBody).then((response) => {
            handleOK(response.data);
        })
        .catch((e) => {
            handleError(e);
        });
    };

    return (
        <Fragment>
            <Header />
            <section className="banner-section">
            <div>
                <div className="section-wrapper">
                    <div className="login-section padding-tb section-bg">
                        <div className="container">
                            <div className="account-wrapper">
                                <h3 className="title">{title}</h3>
                                <form className="account-form" onSubmit={handleSubmit(onSubmit)} id = "loginForm">
                                    <div className="form-group">
                                    <TextField
                                        required
                                        name="email"
                                        autoFocus
                                        margin="dense"
                                        id="email"
                                        inputRef={register({
                                        required: "El campo es requerido",
                                        })}
                                        error={!!errors.email} helperText={errors.email ? errors.email.message : ''}
                                        label="Correo electrónico"
                                        type="text"
                                        width="90%"
                                    />
                                    </div>
                                    <div className="form-group">
                                    <TextField
                                        required
                                        name="password"
                                        autoFocus
                                        margin="dense"
                                        id="password"
                                        inputRef={register({
                                        required: "El campo es requerido",
                                        })}
                                        error={!!errors.password} helperText={errors.password ? errors.password.message : ''}
                                        label="Contraseña"
                                        type="password"
                                        width="90%"
                                    />
                                    </div>
                                    <div className="form-group">
                                        <div className="d-flex justify-content-between flex-wrap pt-sm-2">
                                            <div className="checkgroup">
                                                <input type="checkbox" name="remember" id="remember" />
                                                <label htmlFor="remember">Recuerdame</label>
                                            </div>
                                            <Link to="/forgetpass">Olvidaste la contraseña?</Link>
                                        </div>
                                    </div>
                                    </form>
                                    <div className="form-group text-center">
                                    <Button id="button_login" variant="contained" style={{backgroundColor: "#68dcb9", color: "#FFFFFF"}} type="submit" form="loginForm">
                                        {title}
                                    </Button>
                                    </div>
                                <div className="account-bottom">
                                    <span className="d-block cate pt-10">No tienes una cuenta?  
                                    <Button  id="button_whatsapp" onClick={handleWhatsappPress} >Contáctenos</Button></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose} >
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseError}>
                <Alert onClose={handleCloseError} severity="success" sx={{ width: '100%' }} center>
                La acción se ejecutó de manera correcta.
                </Alert>
            </Snackbar>
        </section>
        <button id="button_whatsapp" variant="round" onClick={handleWhatsappPress} className={styles.buttonWhatsapp}>
            <WhatsAppIcon className={styles.whatsappIcon}/>
          </button>
            <Footer />
        </Fragment>
    );
}
 
export default Login;