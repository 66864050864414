import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Parents from '../parent/ShowParent';
import ParentsAdd from '../parent/FilterParent';
import StudentAdd from '../student/FilterStudent';
import Student from '../student/ShowStudent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const title = "Administración";


const ParentsAdmin = () => {
    return (
        <div className="widget shop-widget">
            <div className="widget-header">
                <h5>{title}</h5>
            </div>
            <div className="widget-wrapper">
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography>Padres de Familia</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <ParentsAdd></ParentsAdd>
                    <Parents dataParent="true"></Parents>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography>Estudiantes</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <StudentAdd></StudentAdd>
                    <Student dataStudent="true"></Student>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    );
}
 
export default ParentsAdmin;