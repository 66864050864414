import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { isAuth } from '../../utils/authenticatedUser';

/**
 * Protected Route component used to show some screens only for authenticated users
 * @param {*}
 */
function ProtectedRoute({ component: Component, ...rest }) {
  const isAuthenticated = isAuth();

  // validate if the user is authenticated and if they have access to the screen
  const getValidateRoute = () => {
    // If the user is not authenticatd, the system will redirect to the login screen
    if (!isAuthenticated) {
      return <Redirect to="/login" />;
    }else {
      return <Route {...rest} render={
        props => <Component {...props} />
      } />;
    }
  };
  return getValidateRoute();

}

ProtectedRoute.propTypes = {
  /** Path of the screen */
  component: PropTypes.func.isRequired,
};

export { ProtectedRoute };
