/** Reducer for List certificate request */

import { fromJS } from 'immutable';
import { SET_LIST_STUDENT } from '../actions/studentConst';

export const initialState = {
  listStudent: []
};

const initialImmutableState = fromJS(initialState);

const student = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_LIST_STUDENT:
      state = initialState;
      return state.listStudent.concat(action.student);
    default:
      return state;
  }
};

export default student;
