import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useForm } from 'react-hook-form';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import UpdateConfirmDialog from './UpdateConfirmDialog';
import DialogTitle from '@material-ui/core/DialogTitle';


/**
 * Function to modificate the pricing plans
 * @param {*} props
 * @returns
 */
function UpdateTeacherDialog(props) {
  const {
    openStatus, onClosed, rowData
  } = props;
  /**
  * Status confirm screen
  */
  const [statusConfirm, setStatusConfirm] = React.useState(false);
  const [modifiedData, setModifiedData] = React.useState({});
  /**
  * Status helper text
  */
  const [helpertext, setHelperText] = React.useState();
  const [error, setError] = React.useState(false);
  const { register, handleSubmit, errors } = useForm();

  const handleCloseConfirm = () => {
    setStatusConfirm(false);
  };

  /**
   * Method to add the register and create que request
   * @param {*} formData
   */
  const onSubmit = async(formData) => {
    const requestBody = {
        id:rowData[0],
        name: formData.name,
        lastName: formData.lastName,
        nit: rowData[3],
        email:formData.email,
        phone:formData.phone,
        birthday:formData.birthday,
        status: rowData[7]
      };
      setModifiedData(requestBody);
      setHelperText('');
      setError(false);
      onClosed();
      setStatusConfirm(true);
    };

  /**
   * Method to recover the period type list and atribute list
   * @param {data} data
   */
  useEffect(() => {
    
  }, [openStatus]);

  /**
   * Method to close the dialog
   */
  const handleCloseAction = () => {
    setHelperText('');
    setError(false);
    onClosed();
  };


  return (
    <div>
    <Grid container justify="center" direction="column">
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openStatus}
        onClose={handleCloseAction}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Añadir</DialogTitle>
        <DialogContent>
          <form noValidate onSubmit={handleSubmit(onSubmit)} id = "modifyForm">
            <div >
                <TextField
                required
                name="name"
                autoFocus
                margin="dense"
                id="name"
                defaultValue={rowData[1]}
                inputRef={register({
                  required: "El campo es requerido",
                })}
                error={!!errors.name} helperText={errors.name ? errors.name.message : ''}
                label="Nombre"
                type="text"
                width="90%"
              />
               <TextField
                required
                name="lastName"
                autoFocus
                margin="dense"
                id="lastName"
                defaultValue={rowData[2]}
                inputRef={register({
                  required: "El campo es requerido",
                })}
                error={!!errors.lastName} helperText={errors.lastName ? errors.lastName.message : ''}
                label="Apellido"
                type="text"                
                style={{marginLeft:"120px"}}
              />
              <TextField
                required
                name="nit"
                disabled
                margin="dense"
                id="nit"
                defaultValue={rowData[3]}
                inputRef={register({
                  required: "El campo es requerido",
                })}
                error={!!errors.nit} helperText={errors.nit ? errors.nit.message : ''}
                label="Cédula"
                type="text"
              />
               <TextField
                required
                name="phone"
                autoFocus
                margin="dense"
                id="phone"
                defaultValue={rowData[5]}
                inputRef={register({
                  required: "El campo es requerido",
                })}
                error={!!errors.phone} helperText={errors.phone ? errors.phone.message : ''}
                label="Teléfono"
                type="number"
                style={{marginLeft:"120px"}}
              />
               <TextField
                required
                name="email"
                autoFocus
                margin="dense"
                id="email"
                defaultValue={rowData[4]}
                inputRef={register({
                  required: "El campo es requerido",
                })}
                error={!!errors.email} helperText={errors.email ? errors.email.message : ''}
                label="Correo eléctronico"
                type="email"
                fullWidth
              />
               <TextField
                required
                name="birthday"
                autoFocus
                margin="dense"
                id="birthday"
                defaultValue={rowData[6]}
                inputRef={register({
                  required: "El campo es requerido",
                })}
                error={!!errors.birthday} helperText={errors.birthday ? errors.birthday.message : ''}
                label="Fecha de nacimiento"
                type="date"
                fullWidth
              />
              <TextField
                required
                name="status"
                disabled
                margin="dense"
                id="status"
                defaultValue={rowData[7]}
                inputRef={register({
                  required: "El campo es requerido",
                })}
                error={!!errors.status} helperText={errors.status ? errors.status.message : ''}
                label="Estado"
                type="text"
                fullWidth
              />
              
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button id="button_close" onClick={handleCloseAction}>Cerrar</Button>
          <Button id="button_agree_modify_teacher" variant="contained" style={{backgroundColor: "#68dcb9", color: "#FFFFFF"}} type="submit" form="modifyForm">
            Modificar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
     <UpdateConfirmDialog openStatusConfirm={statusConfirm} onClosedConfirm={handleCloseConfirm} rowData={modifiedData}/>
     </div>
  );
}

UpdateTeacherDialog.propTypes = {
  /** Status of modify dialog */
  openStatus: PropTypes.bool.isRequired,
  /** Function of modify dialog */
  onClosed: PropTypes.func.isRequired,
  /** Array of pricing plan */
  rowData: PropTypes.array.isRequired

};

export default UpdateTeacherDialog;
