import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import axios from 'axios';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useForm } from 'react-hook-form';
import { setListStudent } from '../actions/studentActions';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
/**
 * Function to add the pricing plans
 * @param {*} props
 * @returns
 */
function AddStudentDialog(props) {
  const {
    openStatus, onClosed
  } = props;

  const endpointTeach = 'http://127.0.0.1:8000/api';
  const [parent, setParent] = useState([]);
  const [course, setCourse] = useState([]);

   /**
  * const teacher list
  */
  const listStudent = useSelector(state => state.student, shallowEqual);
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();  
  const endpoint = 'http://127.0.0.1:8000/api/student';
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [parentId, setParentId] = useState("");
  const [courseId, setCourseId] = useState("");
  const [helperTextType, setHelperTextType] = React.useState();
  const [errorType, setErrorType] = React.useState(false);

  const handleCloseError = (event, reason) => {
    setOpen(false);
  };

  const handleClose = (event, reason) => {
    setOpenError(false);
  };

  
  //const dispatch = useDispatch();

  /**
 * Method to added the new register in the table
 * @param {data} data
 */
  const handleAdd = (data) => {
    const newListStudent = [...listStudent];
    newListStudent.push(data);
    dispatch(setListStudent(newListStudent));
  };


  /**
   * Method to close the dialog and show the success message
   * @param {data} data
   */
  const handleOK = (data) => {
    handleAdd(data.data);
    onClosed();
    setOpen(true);
    setErrorType(false);
  };

  /**
   * Method to close the dialog
   */
  const handleError = (response) => {
    if(response.response.data.message.errorInfo[2].includes("Duplicate")){
      setErrorMessage('El estudiante ya existe.');
    }else{
      setErrorMessage('La acción no se pudo realizar.');      
    }
    onClosed();
    setOpenError(true);
  };

   /**
  * Method to set the list pricing plans
  */
   const getAllActiveParent = async() => {
    const response = await axios.get(endpointTeach+'/parents');
    setParent(response.data);
  };

     /**
  * Method to set the list pricing plans
  */
     const getAllActiveCourse = async() => {
      const response = await axios.get(endpointTeach+'/courses');
      setCourse(response.data);
    };
  
    /**
     * Retrieve pricing plan list
     */
    useEffect(() => {
      getAllActiveParent(); 
      getAllActiveCourse(); 
    }, []);

  /**
   * Method to add the register and create que request
   * @param {*} formData
   */
  const onSubmit = async(formData) => {
    if (parentId === "" || courseId === "") {
      // validate the required field role type
        setHelperTextType("Valor requerido");
        setErrorType(true);
    } else {     
      const requestBody = {
        name: formData.name,
        lastName: formData.lastName,
        description: formData.description, 
        birthday:formData.birthday,
        nit:formData.nit,
        representative_id: parentId.id,
        course_id: courseId.id,
      };
      await axios.post(endpoint, requestBody).then((response) => {
        handleOK(response);
      })
      .catch((e) => {
        handleError(e);
      });
    }
  };

  /**
   * Method to close the dialog
   */
  const handleCloseAction = () => {
    onClosed();
  };

  /**
   * Method to control the combobox of role type
   */
  const handleChangeType = (event) => {
    setCourseId(event.target.value);
  };

    /**
   * Method to control the combobox of role type
   */
    const handleChangeParent = (event) => {
      setParentId(event.target.value);
    };

  return (
    <Grid container justify="center" direction="column">
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openStatus}
        onClose={handleCloseAction}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Añadir</DialogTitle>
        <DialogContent>
          <form noValidate onSubmit={handleSubmit(onSubmit)} id = "addForm">
            <div >
                <TextField
                required
                name="name"
                autoFocus
                margin="dense"
                id="name"
                inputRef={register({
                  required: "El campo es requerido",
                })}
                error={!!errors.name} helperText={errors.name ? errors.name.message : ''}
                label="Nombre"
                type="text"
                width="90%"
              />
               <TextField
                required
                name="lastName"
                autoFocus
                margin="dense"
                id="lastName"
                inputRef={register({
                  required: "El campo es requerido",
                })}
                error={!!errors.lastName} helperText={errors.lastName ? errors.lastName.message : ''}
                label="Apellido"
                type="text"                
                style={{marginLeft:"120px"}}
              />
              <TextField
                required
                name="nit"
                autoFocus
                margin="dense"
                id="nit"
                inputRef={register({
                  required: "El campo es requerido",
                })}
                error={!!errors.nit} helperText={errors.nit ? errors.nit.message : ''}
                label="Cédula"
                type="text"
              />
               <TextField
                required
                name="description"
                autoFocus
                multiline
                margin="dense"
                id="description"
                inputRef={register({
                  required: "El campo es requerido",
                })}
                error={!!errors.description} helperText={errors.description ? errors.description.message : ''}
                label="Descripción"
                type="text"
                style={{marginLeft:"120px"}}
              />
               <TextField
                required
                name="birthday"
                autoFocus
                margin="dense"
                id="birthday"
                inputRef={register({
                  required: "El campo es requerido",
                })}
                error={!!errors.birthday} helperText={errors.birthday ? errors.birthday.message : ''}
                label="Fecha de nacimiento"
                type="date"
                fullWidth
                defaultValue={new Date()}
              />
              <br/>
                <FormControl width="90%" >
                  <InputLabel required error={errorType} htmlFor="status-helper">Padre de Familia:</InputLabel>
                  <Select
                    name="parent"
                    labelId="demo-checkbox-label"
                    id="parent"
                    fullWidth
                    value={parentId}
                    onChange={handleChangeParent}
                    input={<Input />}
                    MenuProps={MenuProps}
                    style={{width:"200px"}}
                  >
                    <MenuItem name="" value="">
                      Ninguno
                    </MenuItem>
                    {parent.map((value, key) => (
                      <MenuItem name={value} key={key.toString()} value={value}>
                         {value.name + " " + value.lastName}
                      </MenuItem>
                    ))}
                  </Select>
                  {errorType && <FormHelperText error>{helperTextType}</FormHelperText>}
                </FormControl>
                <br/>
                <FormControl width="90%" >
                  <InputLabel required error={errorType} htmlFor="status-helper">Curso:</InputLabel>
                  <Select
                    name="course"
                    labelId="demo-checkbox-label"
                    id="course"
                    fullWidth
                    value={courseId}
                    onChange={handleChangeType}
                    input={<Input />}
                    MenuProps={MenuProps}
                    style={{width:"200px"}}
                  >
                    <MenuItem name="" value="">
                      Ninguno
                    </MenuItem>
                    {course.map((value, key) => (
                      <MenuItem name={value} key={key.toString()} value={value}>
                         {value.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errorType && <FormHelperText error>{helperTextType}</FormHelperText>}
                </FormControl>

            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button id="button_close" onClick={handleCloseAction}>Cerrar</Button>
          <Button id="button_agree_add_student" variant="contained" style={{backgroundColor: "#68dcb9", color: "#FFFFFF"}} type="submit" form="addForm">
            Añadir
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose} >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="success" sx={{ width: '100%' }} center>
          La acción se ejecutó de manera correcta.
        </Alert>
      </Snackbar>
    </Grid>
  );
}

AddStudentDialog.propTypes = {
  /** status of add dialog */
  openStatus: PropTypes.bool.isRequired,
  /** function of add dialog */
  onClosed: PropTypes.func.isRequired
};

export default AddStudentDialog;
