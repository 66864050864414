
const subTitle = "Creative Kids te brinda varios servicios entre ellos";
const title = "Principales Servicios";



const categoryList = [
    {
        title: 'ELEMENTARY  BILINGÜE ',
        desc: 'Metodologías STEAM y Montessori. Animación a la lectura. Orientación a las Ciencias, la Tecnología, el Arte, Programación, etc.',
    },
    {
        title: 'PRE ESCOLAR BILINGÜE',
        desc: 'Metodologías STEAM y Montessori.  Formamos niños críticos y reflexivos, actividades para prevenir problemas de aprendizaje.',
    },
    {
        title: 'CLASES DE INGLÉS',
        desc: 'Desarrollo de competencias comunicativas a través de Reading, Listening, Writing y Speaking . Programa de 7 niveles académicos.',
    },
    {
        title: 'ACTIVIDADES EXTRACURRICULARES BILINGÜES',
        desc: 'Clases de fútbol, ballet, gimnasia, experimentos y más',
    },
    {
        title: 'CAMPAMENTOS VACACIONALES BILINGÜES',
        desc: 'Excurciones, deportes, natación',
    },
    {
        title: 'TAREAS DIRIGIDAS ESPECIALIZADAS',
        desc: 'Acompañamiento pedagógico',
    },
    {
        title: 'TERAPIAS ESPECIALIZADAS ',
        desc: 'Lenguaje       Comportamentales        Ocupacionales',
    },
    {
        title: 'ACOMPAÑAMIENTO PEDAGÓGICO',
        desc: 'Proceso lector escritor, matemáticas, inglés',
    },
]

const CategoryTwo = () => {
    return (
        <div className="category-section padding-tb section-bg style-2">
            <div className="container">
                <div className="section-header text-center">
                    <h2 className="title">{title}</h2>
                    <span className="subtitle" class="notranslate">{subTitle}</span>
                </div>
                <div className="section-wrapper">
                    <div className="row g-4 justify-content-center row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 row-cols-1">
                        {categoryList.map((val, i) => (
                            <div className="col" key={i}>
                                <div className="category-item text-center">
                                    <div className="category-inner">
                                        <br/> <br/>
                                        <div className="category-content">
                                            <h6>{val.title}</h6>
                                            <span class="notranslate">{val.desc}</span>
                                        </div> <br/> <br/>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default CategoryTwo;