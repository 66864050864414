/** Reducer for List certificate request */

import { fromJS } from 'immutable';
import { SET_LIST_TEACHER } from '../actions/teacherConst';

export const initialState = {
  listTeacher: []
};

const initialImmutableState = fromJS(initialState);

const teacher = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_LIST_TEACHER:
      state = initialState;
      return state.listTeacher.concat(action.teacher);
    default:
      return state;
  }
};

export default teacher;
