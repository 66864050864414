import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import axios from 'axios';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';


function List (props)  {
  const {
    openStatus, onClosed, data
  } = props;
  const endpoint = 'http://127.0.0.1:8000/api';
  const [listTeacher, setListTeacher] = useState(data);
    const [rowDataState, setRowDataState] = useState([]);
    const [statusButton, setStatusButton] = React.useState(false);

      const [tableColumns, setTableColumns] = useState([]);
      const [tableList, setTableList] = useState([]);
      
      const columns = [
        {
          label: 'Curso',
          name: 'course',
          options: {
            filter: true,
          }
        },
        {
          label: 'Materia',
          name: 'sub',
          options: {
            filter: true,
          }
        },
        {
          label: 'nombre',
          name: 'student',
          options: {
            filter: true,
          }
        },
        {
          label: 'Apellido',
          name: 'lastName',
          options: {
            filter: true,
          }
        },
        {
          label: 'Actividad',
          name: 'name',
          options: {
            filter: true,
          }
        },
        {
          label: 'Calificación',
          name: 'value',
          options: {
            display:true,
            filter: true,
          }
        },
      ];

     /**
 * Method to set the documents list and tabe columns
 */
  useEffect(() => {
    if (listTeacher && listTeacher.length >= 0) {
      setTableColumns(columns);
      setTableList(listTeacher);
    }
  }, [listTeacher]);
 
  const getAllActivities = async() => {
    const requestBody = {
      course_id: data.course_id,
      student_id: data.student_id,
    };

  await axios.post(endpoint+'/findAllByCourse', requestBody).then((response) => {
      setListTeacher(response.data);
    })
    .catch((e) => {
      console.log(e);
    });
    return listTeacher;
  };

  useEffect(() => {
    getAllActivities();
  }, []);


  
      /**
       * Method to recoer the specifid values of the row
       * @param {rowData} rowData
       */
      const handleRowData = (rowData) => {
        const rowDataTemp = [];
        rowDataTemp.length = 0;
        rowDataTemp.push(rowData[0]);
        rowDataTemp.push(rowData[1]);
        rowDataTemp.push(rowData[2]);
        rowDataTemp.push(rowData[3]);
        rowDataTemp.push(rowData[4]);
        rowDataTemp.push(rowData[5]);  
        rowDataTemp.push(rowData[6]); 
        rowDataTemp.push(rowData[7]); 
        setRowDataState(rowDataTemp);   
      };
    
      const handleCloseButton = () => {
        setStatusButton(false);
      };
    
      const options = {
        selectableRowsHideCheckboxes: true,
        selectableRows: 'single',
        filterType: 'dropdown',
        responsive: 'standard',
        print: true,
        selectableRowsOnClick: false,
        rowsPerPage: 10,
        downloadOptions: {
          filename: 'lp',
          filterOptions: {
            useDisplayedRowsOnly: true,
          }
        },
        onDownload: (buildHead, buildBody, columnsData, data) => {
          const val = '\uFEFF' + `${buildHead(columnsData)}${buildBody(data)}`.trim();
          return val;
        },
        onRowClick: (rowData) => {          
          handleRowData(rowData);
          setStatusButton(true);
        }
      };
    
      return (
        <div>
        <div>
          <MUIDataTable
            title='Información'
            data={tableList}
            columns={tableColumns}
            options={options}
          />
         </div>
        </div>
      );
}

List.propTypes = {
  /** Status of modify dialog */
  openStatus: PropTypes.bool.isRequired,
  /** Function of modify dialog */
  onClosed: PropTypes.func.isRequired,
  /** Array of pricing plan */
  data: PropTypes.object.isRequired,
};

List.defaultProps = {
  dataTeacher: "true"
};

export default List