import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Teachers from '../teacher/ShowTeacher';
import TeachersAdd from '../teacher/FilterTeacher';
import PrincipalCourse from '../course/PrincipalCourse';
import SubjectsAdd from '../subject/FilterSubject';
import Subjects from '../subject/ShowSubject';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const title = "Administración";


const TeachersAdmin = () => {
    return (
        <div className="widget shop-widget">
            <div className="widget-header">
                <h5>{title}</h5>
            </div>
            <div className="widget-wrapper">
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography>Profesores</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <TeachersAdd></TeachersAdd>
                    <Teachers dataTeacher="true"></Teachers>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography>Cursos</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <PrincipalCourse dataCourse="true"></PrincipalCourse>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography>Materias</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <SubjectsAdd></SubjectsAdd>
                    <Subjects dataSubject="true"></Subjects>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    );
}
 
export default TeachersAdmin;