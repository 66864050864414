
import { Link } from "react-router-dom";

const subTitle = "Elementary and Preschool";
const title = <h2 className="title" class="notranslate">Creative Kids</h2>;
const desc = "“Resulta imprescindible permitir que los niños desarrollen";
const desc1 = "su autonomía y habilidades, sin interferencias que no"
const desc2 = "sean 100% necesarias. Cuando los adultos ofrecen ayudas";
const desc3 = "excesivas o innecesarias obstaculizan el proceso natural";
const desc4 = "de aprendizaje y crecimiento del niño.”";
const desc5 = "(María Montessori)";



const BannerFive = () => {
    return (
        <section className="banner-section style-5">
            <div className="container">
                <div className="section-wrapper">
                    <div className="row">
                        <div className="offset-md-6 col-xl-6">
                            <div className="banner-content"> 
                                <h4 className="subtitle" class="notranslate">{subTitle}</h4>
                                {title}
                                <p className="desc">{desc}</p>
                                <p className="desc">{desc1}</p>
                                <p className="desc">{desc2}</p>
                                <p className="desc">{desc3}</p>
                                <p className="desc">{desc4}</p>
                                <p className="desc">{desc5}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default BannerFive;