import { Component, Fragment } from "react";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Footer from "../../bootstrap/component/layout/footer-4";
import Header from "../../bootstrap/component/layout/header-5";
import Banner from "../../bootstrap/component/section/banner-5";
import About from "../../bootstrap/component/section/about-3";
import Category from "../../bootstrap/component/section/category-2";
import Course from "../../bootstrap/component/section/course-4";
import GoogleMap from "../../bootstrap/component/sidebar/googlemap";
import Fab from '@material-ui/core/Fab';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import styles from './styles/home.module.css';
// import Slider from './Slider';

 // Redirect to whatsapp window
 const handleWhatsappPress = async () => {
  const URL = "https://wa.me/+593998590707?text=Hola gracias por comunicarte con Creative Kids. Con mucho gusto te ayudamos. ¿Cuál es tu duda?";
  window.open(URL);
};

function Home(props) {
    return (
        <Fragment>
            <Header />
          <section id="banner">
            <Banner />
          </section>
          <section id="services">
            <Category />
          </section>
          <section id="aboutUs">
            <Course />
          </section>
          <section id="contactUs">
            <Grid container spacing={1} direction="row">
                <Grid item xs={12} md={6}>
                    <GoogleMap />
                </Grid>
                <Grid item xs={12} md={6}>
                    <About />
                    <Button  id="button_whatsapp" variant="contained" style={{backgroundColor: "#68dcb9"}} onClick={handleWhatsappPress} className={styles.buttonContact} >Contáctenos</Button>
                </Grid>
            </Grid>
          </section>
            <Footer />
          <button id="button_whatsapp" variant="round" onClick={handleWhatsappPress} className={styles.buttonWhatsapp}>
            <WhatsAppIcon className={styles.whatsappIcon}/>
          </button>
        </Fragment>
    );
}
 
export default Home;