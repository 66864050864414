
import { Link } from "react-router-dom";
import img from "../../../assets/images/logo/09.jpg"

const title = "Cumpleañer@s";

const postList = [
    {
        name: 'Thiago Moreno',
        date: '15-03-2023',
    },
    {
        name: 'Andrea Almeida',
        date: '20-03-2023',
    },
    {
        name: 'Diego Lopez',
        date: '29-03-2023',
    }
]

const PopularPost = () => {
    return (
        <div className="widget widget-post">
            <div className="widget-header">
                <h5 className="title">{title}</h5>
            </div>
            <ul className="widget-wrapper">
                {postList.map((val, i) => (
                    <li className="d-flex flex-wrap justify-content-between" key={i}>
                        <div className="post-thumb">
                            <Link to="/blog-single"><img src={img} alt="" /></Link>
                        </div>
                        <div className="post-content">
                            <Link to="/blog-single"><h6>{val.name}</h6></Link>
                            <p>{val.date}</p>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}
 
export default PopularPost;