import { Component, Fragment } from "react";
import Grid from '@material-ui/core/Grid';
import Footer from "../../bootstrap/component/layout/footer-4";
import Header from "../../bootstrap/component/layout/header-5.1";
import Principal from "../teachersAction/Principal";


function Home(props) {
    return (
        <Fragment>
            <Header />
            <Principal/>
            <Footer />
        </Fragment>
    );
}
 
export default Home;