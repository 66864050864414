const title = <h2 class="notranslate">Creative Kids</h2>;
const desc = "Creative Kids es un Centro de Educación básica y Pre escolar  Bilingüe que se encuentra comprometido con el desarrollo de todas las destrezas y capacidades  de nuestros niños y proporcionarles herramientas para conseguir todos sus objetivos.";

const detailsList = [
    {
        leftText: 'Tenemos 10',
        clone: '',
        rightText: 'años de experiencia',
    },
    {
        leftText: 'Directora',
        clone: ':',
        rightText: 'Nadia Flor Romero',
    },
    {
        leftText: 'Teléfono',
        clone: ':',
        rightText: '+593 99 859 0707',
    },
    {
        leftText: 'Mail',
        clone: ':',
        rightText: 'creativekidsecuador@gmail.com',
    },
    {
        leftText: 'Dirección',
        clone: ':',
        rightText: 'Juan Ramírez N35-79 y German Alemán, Quito, Ecuador, 1723',
    },
]


const AboutThree = () => {
    return (
        <section className="style-6 padding-tb">
            <div className="container">
                <div className="about-items">
                    <div className="about-item">
                        <div className="about-content">
                            {title}
                            <p class="notranslate">{desc}</p>
                            <ul className="details lab-ul">
                                {detailsList.map((val, i) => (
                                    <li key={i}>{val.leftText} {val.clone}{val.rightText}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default AboutThree;