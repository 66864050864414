


const GoogleMap = () => {
    return (
        <div className="map-area">
            <div className="maps">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.798763107626!2d-78.47794768082618!3d-0.17939691916016876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91d59b81379a036b%3A0xbd1c01c77dc5da67!2sCreative%20Kids!5e0!3m2!1sen!2sbd!4v1679373919043!5m2!1sen!2sbd" ></iframe>
            </div>
        </div>
    );
}
 
export default GoogleMap;