
import './App.css';
import Home from './components/landing/Home';
import HomeTeacher from './components/landing/HomeTeacher';
import HomeParent from './components/landing/HomeParent';
import LoginPage from "./components/login/login";
import FacebookLogin from "./components/login/facebookLogin";
import GoogleLogin from "./components/login/googleLogin";
import SignupPage from "./components/signUp/signup";
import ForgetPass from "./components/forgetPass/forgetpass";
import ShowTeacher from './components/teacher/ShowTeacher';
import {ProtectedRoute} from './components/login/ProtectedRoute';
import PrincipalNot from "./components/teachersAction/PrincipalNot";
import { Switch, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
        <Switch>
          <Route exact path='/' component= {Home}/>
          <Route exact path='/teachers' component= {ShowTeacher}/>
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/facebookLogin" component={FacebookLogin} />
          <Route exact path="/googleLogin" component={GoogleLogin} />
          <Route exact path="/signup" component={SignupPage} />
				  <Route exact path="/forgetpass" component={ForgetPass} />
          <ProtectedRoute exact path='/publicTeachers' component={HomeTeacher}/>
          <ProtectedRoute exact path='/notifications' component= {PrincipalNot}/>
          <ProtectedRoute exact path='/publicParents' component= {HomeParent}/>
        </Switch>
    </div>
  );
}

export default App;
