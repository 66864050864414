import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import DeleteParentDialog from './dialogs/DeleteParentDialog';
import UpdateParentDialog from './dialogs/UpdateParentDialog';
import ParentForm from './reveal/ParentForm';

function TabContainer(props) {
  const { children } = props;
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.array.isRequired,
};

/**
 * Function to present the reveal of pricing plans and their actions
 * @param {*} props
 * @returns
 */
function ActionDialogParent(props) {
  const {
    openStatus, onClosed, rowData
  } = props;
  const [statusModify, setStatusModify] = React.useState(false);
  const [statusRemove, setStatusRemove] = React.useState(false);

  const [value, setValue] = useState(0);
  const handleChange = (event, valueData) => {
    setValue(valueData);
  };

  const handleClickOpenModify = () => {
    onClosed();
    setStatusModify(true);
  };

  const handleClickCloseModify = () => {
    setStatusModify(false);
  };

  const handleClickOpenRemove = () => {
    onClosed();
    setStatusRemove(true);
  };

  const handleClickCloseRemove = () => {
    setStatusRemove(false);
  };

  /**
   * Method to close the dialog
   */
  const handleCloseAction = () => {
    onClosed();
  };

  return (

    <Paper>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openStatus}
        onClose={onClosed}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="user-menu-dialog"> Opciones</DialogTitle>
        <DialogContent>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              variant="fullWidth"
              scrollButtons="on"
              indicatorColor="#68dcb9"
              textColor="#ffdf40"
            >
              <Tab style={{backgroundColor: "#ffdf4079"}} label="Información" />

            </Tabs>
          </AppBar>
          {value === 0 && <TabContainer><ParentForm rowData={rowData}/></TabContainer>}
         
        </DialogContent>
        <DialogActions>
          <Button id="button_close" onClick={handleCloseAction}> Cerrar </Button>
          {rowData[6] !== 'Removido' ? <Button id="button_modify_parents" variant="contained" onClick={handleClickOpenModify} style={{backgroundColor: "#68dcb9", color: "#FFFFFF"}}> Modificar
          </Button> : ''}
          {rowData[6] !== 'Removido' ? <Button id="button_remove_parentss" variant="contained" onClick={handleClickOpenRemove} style={{backgroundColor: "#68dcb9", color: "#FFFFFF"}}> Eliminar
          </Button> : ''}
        </DialogActions>
      </Dialog>

      <DeleteParentDialog openStatus={statusRemove} onClosed= {handleClickCloseRemove} rowData={rowData}/>
      <UpdateParentDialog openStatus={statusModify} onClosed= {handleClickCloseModify} rowData={rowData}/>
    </Paper>

  );
}

ActionDialogParent.propTypes = {
  /** Style classes */
  openStatus: PropTypes.bool.isRequired,
  /** Function of modify dialog */
  onClosed: PropTypes.func.isRequired,
  /** Array of pricing plan */
  rowData: PropTypes.array.isRequired
};

export default ActionDialogParent;
