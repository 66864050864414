import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useForm } from 'react-hook-form';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import UpdateConfirmDialog from './UpdateConfirmDialog';
import DialogTitle from '@material-ui/core/DialogTitle';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

/**
 * Function to modificate the pricing plans
 * @param {*} props
 * @returns
 */
function UpdateStudentDialog(props) {
  const {
    openStatus, onClosed, rowData, course, parent
  } = props;
  /**
  * Status confirm screen
  */
  const [statusConfirm, setStatusConfirm] = React.useState(false);
  const [modifiedData, setModifiedData] = React.useState({});
  const [parentId, setParentId] = useState("");
  const [courseId, setCourseId] = useState("");
  const [helperTextType, setHelperTextType] = React.useState();
  const [errorType, setErrorType] = React.useState(false);
  /**
  * Status helper text
  */
  const [helpertext, setHelperText] = React.useState();
  const [error, setError] = React.useState(false);
  const { register, handleSubmit, errors } = useForm();

  const handleCloseConfirm = () => {
    setStatusConfirm(false);
  };

  /**
   * Method to add the register and create que request
   * @param {*} formData
   */
  const onSubmit = async(formData) => {
    if (parentId === "" || courseId === "") {
      // validate the required field role type
        setHelperTextType("Valor requerido");
        setErrorType(true);
    } else { 
      const requestBody = {
          id:rowData[0],
          name: formData.name,
          lastName: formData.lastName,
          description: formData.description, 
          birthday:formData.birthday,
          nit:rowData[3],
          representative_id: parentId.id,
          course_id: courseId.id,
          status: 'Active'
        };
        setModifiedData(requestBody);
        setHelperText('');
        setError(false);
        onClosed();
        setStatusConfirm(true);
      }
    };

  /**
   * Method to recover the period type list and atribute list
   * @param {data} data
   */
  useEffect(() => {
    if (openStatus) {
      course.map((option) => {
        if (option.id === rowData[9]) {
          setCourseId(option);
        }
      });
      parent.map((option) => {
        if (option.id === rowData[7]) {
          setParentId(option);
        }
      });
    }
  }, [openStatus]);

  /**
   * Method to close the dialog
   */
  const handleCloseAction = () => {
    setHelperText('');
    setError(false);
    onClosed();
  };

 /**
   * Method to control the combobox of role type
   */
 const handleChangeType = (event) => {
  setCourseId(event.target.value);
};

  /**
 * Method to control the combobox of role type
 */
  const handleChangeParent = (event) => {
    setParentId(event.target.value);
  };


  return (
    <div>
    <Grid container justify="center" direction="column">
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openStatus}
        onClose={handleCloseAction}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Añadir</DialogTitle>
        <DialogContent>
          <form noValidate onSubmit={handleSubmit(onSubmit)} id = "modifyForm">
            <div >
                <TextField
                required
                name="name"
                autoFocus
                margin="dense"
                id="name"
                defaultValue={rowData[1]}
                inputRef={register({
                  required: "El campo es requerido",
                })}
                error={!!errors.name} helperText={errors.name ? errors.name.message : ''}
                label="Nombre"
                type="text"
                width="90%"
              />
               <TextField
                required
                name="lastName"
                autoFocus
                margin="dense"
                id="lastName"
                defaultValue={rowData[2]}
                inputRef={register({
                  required: "El campo es requerido",
                })}
                error={!!errors.lastName} helperText={errors.lastName ? errors.lastName.message : ''}
                label="Apellido"
                type="text"                
                style={{marginLeft:"120px"}}
              />
              <TextField
                required
                name="nit"
                disabled
                margin="dense"
                id="nit"
                defaultValue={rowData[3]}
                inputRef={register({
                  required: "El campo es requerido",
                })}
                error={!!errors.nit} helperText={errors.nit ? errors.nit.message : ''}
                label="Cédula"
                type="text"
              />
               <TextField
                required
                name="description"
                autoFocus
                multiline
                margin="dense"
                id="description"
                defaultValue={rowData[4]}
                inputRef={register({
                  required: "El campo es requerido",
                })}
                error={!!errors.description} helperText={errors.description ? errors.description.message : ''}
                label="Descripción"
                type="text"
                style={{marginLeft:"120px"}}
              />
               <TextField
                required
                name="birthday"
                autoFocus
                margin="dense"
                id="birthday"
                defaultValue={rowData[5]}
                inputRef={register({
                  required: "El campo es requerido",
                })}
                error={!!errors.birthday} helperText={errors.birthday ? errors.birthday.message : ''}
                label="Fecha de nacimiento"
                type="date"
                fullWidth
              />
              <br/>
                <FormControl width="90%" >
                  <InputLabel required error={errorType} htmlFor="status-helper">Padre de Familia:</InputLabel>
                  <Select
                    name="parent"
                    labelId="demo-checkbox-label"
                    id="parent"
                    fullWidth
                    value={parentId}
                    onChange={handleChangeParent}
                    input={<Input />}
                    MenuProps={MenuProps}
                    style={{width:"200px"}}
                  >
                    <MenuItem name="" value="">
                      Ninguno
                    </MenuItem>
                    {parent.map((value, key) => (
                      <MenuItem name={value} key={key.toString()} value={value}>
                         {value.name + " " + value.lastName}
                      </MenuItem>
                    ))}
                  </Select>
                  {errorType && <FormHelperText error>{helperTextType}</FormHelperText>}
                </FormControl>
                <br/>
                <FormControl width="90%" >
                  <InputLabel required error={errorType} htmlFor="status-helper">Curso:</InputLabel>
                  <Select
                    name="course"
                    labelId="demo-checkbox-label"
                    id="course"
                    fullWidth
                    value={courseId}
                    onChange={handleChangeType}
                    input={<Input />}
                    MenuProps={MenuProps}
                    style={{width:"200px"}}
                  >
                    <MenuItem name="" value="">
                      Ninguno
                    </MenuItem>
                    {course.map((value, key) => (
                      <MenuItem name={value} key={key.toString()} value={value}>
                         {value.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errorType && <FormHelperText error>{helperTextType}</FormHelperText>}
                </FormControl>
              
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button id="button_close" onClick={handleCloseAction}>Cerrar</Button>
          <Button id="button_agree_modify_student" variant="contained" style={{backgroundColor: "#68dcb9", color: "#FFFFFF"}} type="submit" form="modifyForm">
            Modificar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
     <UpdateConfirmDialog openStatusConfirm={statusConfirm} onClosedConfirm={handleCloseConfirm} rowData={modifiedData}/>
     </div>
  );
}

UpdateStudentDialog.propTypes = {
  /** Status of modify dialog */
  openStatus: PropTypes.bool.isRequired,
  /** Function of modify dialog */
  onClosed: PropTypes.func.isRequired,
  /** Array of pricing plan */
  rowData: PropTypes.array.isRequired,
   /** Array of pricing plan */
   parent: PropTypes.array.isRequired,
    /** Array of pricing plan */
  course: PropTypes.array.isRequired

};

export default UpdateStudentDialog;
