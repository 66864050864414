
import img1 from "../../../assets/images/blog/01.jpg";
import img2 from "../../../assets/images/blog/02.jpg";
import img3 from "../../../assets/images/blog/03.jpg";
import img4 from "../../../assets/images/blog/04.jpg";
import img5 from "../../../assets/images/blog/05.jpg";
import img6 from "../../../assets/images/blog/06.jpg";
import img7 from "../../../assets/images/blog/07.jpg";
import img8 from "../../../assets/images/blog/08.jpg";
import img9 from "../../../assets/images/blog/09.jpg";
import img10 from "../../../assets/images/blog/10.jpg";
import img11 from "../../../assets/images/blog/11.jpg";
import img12 from "../../../assets/images/blog/12.jpg";

const title = "Galería de Fotos";

const instagramList = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12];

const Instagram = () => {
    return (
        <div className="widget widget-instagram">
            <div className="widget-header">
                <h5 className="title" style={{color:"#27dcb4"}}>{title}</h5>
            </div>
            <ul className="widget-wrapper d-flex flex-wrap justify-content-center">
                {instagramList.map((val, i) => (
                    <li key={i}><a href={val} target="_blank"><img src={val} alt="..." /></a></li>
                ))}
            </ul>
        </div>
    );
}
 
export default Instagram;