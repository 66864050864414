
import logo from '../../../assets/images/creativeKids/logo.png';

const addressList = [
    {
        iconName: 'icofont-google-map',
        text: 'Juan Ramírez N35-79 y German Alemán.',
    },
    {
        iconName: 'icofont-phone',
        text: '+593 99 859 0707',
    },
    {
        iconName: 'icofont-envelope',
        text: 'creativekidsecuador@gmail.com',
    },
]

const socialList = [
    {
        iconName: 'icofont-facebook',
        siteLink: 'https://www.facebook.com/terapiasavanzar?mibextid=LQQJ4d',
        className: 'facebook',
    },
    {
        iconName: 'icofont-instagram',
        siteLink: 'https://instagram.com/creativekidsecuador?igshid=NzZhOTFlYzFmZQ==',
        className: 'instagram',
    },
]

const FooterFour = () => {
    return (
        <footer className="style-2 yellow-color-section">
            <div className="footer-top padding-tb">
                <div className="container">
                    <div className="row g-4 row-cols-xl-4 row-cols-sm-2 row-cols-1 justify-content-center">
                        <div className="col">
                            <div className="footer-item our-address">
                                <div className="footer-inner">
                                    <div className="footer-content">
                                        <div className="title">
                                            <img src={logo} alt="education" width='500px'/>
                                        </div>
                                        <div className="content">
                                            <ul className="lab-ul office-address">
                                                {addressList.map((val, i) => (
                                                    <li key={i}><i className={val.iconName}></i>{val.text}</li>
                                                ))}
                                            </ul>
                                            <ul className="lab-ul social-icons">
                                                {socialList.map((val, i) => (
                                                    <li key={i}>
                                                        <a href={val.siteLink} className={val.className}><i className={val.iconName}></i></a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; 2023 </p>
            </div>
        </footer>
    );
}
 
export default FooterFour;