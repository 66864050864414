import React, {useState, useEffect} from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@mui/material/Snackbar';
import Button from '@material-ui/core/Button';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MuiAlert from '@mui/material/Alert';
import { useForm } from 'react-hook-form';
import axios from 'axios';

const title = "Notificaciones";
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const Notification = () => {
    const { register, handleSubmit, errors } = useForm();
    const [helperText, setHelperText] = useState('');
    const [error, setError] = useState(false);
    const endpoint = 'http://127.0.0.1:8000/api';
    const [receptorList, setReceptorList] = useState([]);
    const [courseId, setCourseId] = useState("");
    const [helperTextType, setHelperTextType] = React.useState();
    const [errorType, setErrorType] = React.useState(false);
    const [open, setOpen] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    
    /**
     * Handle data modified by the user in the combo box
     */
    const handleChange = event => {
        setCourseId(event.target.value);
    };

    const getAllTeachers = async() => {
        const response = await axios.get(endpoint+'/courses');
        setReceptorList(response.data);
        return response.data;
    }

       /**
     * Method to set the documents list and tabe columns
     */
    useEffect( () => {
      getAllTeachers();      
    }, [])

     /**
   * Method to add the register and create que request
   * @param {*} formData
   */
  const onSubmit = async(formData) => {
    if (courseId === "") {
      // validate the required field role type
        setHelperTextType("Valor requerido");
        setErrorType(true);
    } else {     
      const requestBody = {
        activity: formData.activity,
        description: formData.description,
        id: courseId.id,
      };
      await axios.post(endpoint+'/sendNotification', requestBody).then((response) => {
        handleOK(response);
      })
      .catch((e) => {
        handleError(e);
      });
    }
  };

   /**
   * Method to close the dialog and show the success message
   * @param {data} data
   */
   const handleOK = (data) => {
    setOpen(true);
    setErrorType(false);
  };

  /**
   * Method to close the dialog
   */
  const handleError = (response) => {
   setErrorMessage('La acción no se pudo realizar.');      
    setOpenError(true);
  };

  const handleCloseError = (event, reason) => {
    setOpen(false);
  };

  const handleClose = (event, reason) => {
    setOpenError(false);
  };

    return (
        <div id="respond" className="comment-respond mb-lg-0">
            <h4 className="title-border">{title}</h4>
            <div className="add-comment">
            <form noValidate onSubmit={handleSubmit(onSubmit)} id = "addForm">
            <FormControl width="90%" >
                    <InputLabel required error={error} htmlFor="status-helper" >Destinatarios: </InputLabel>
                    <Select
                        name="receptor"
                        value={courseId}
                        onChange={handleChange}
                        input={<Input />}
                        style={{width:"500px"}}
                    >
                        <MenuItem disabled value="0">
                        <em>Ninguno</em>
                        </MenuItem>
                        {receptorList.map((item, key) => (
                        <MenuItem value={item} key= {key.toString()} >{item.name}</MenuItem>
                        ))}
                    </Select>
                    {errorType && <FormHelperText error>{helperTextType}</FormHelperText>}
                </FormControl>
                    
                    <TextField
                        required
                        name="activity"
                        autoFocus
                        margin="dense"
                        id="activity"
                        inputRef={register({
                        required: "El campo es requerido",
                        })}
                        error={!!errors.activity} helperText={errors.activity ? errors.activity.message : ''}
                        label="Actividad"
                        type="text"
                        fullWidth
                    />
                    <TextField
                        required
                        name="description"
                        autoFocus
                        multiline
                        margin="dense"
                        rows="7" 
                        id="description"
                        inputRef={register({
                        required: "El campo es requerido",
                        })}
                        error={!!errors.description} helperText={errors.description ? errors.description.message : ''}
                        label="Descripción"
                        type="text"
                        fullWidth
                    />
                     <Button id="button_agree_add_student" variant="contained" style={{backgroundColor: "#68dcb9", color: "#FFFFFF"}} type="submit" form="addForm">
                        Enviar
                    </Button>
                </form>
                <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose} >
                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                    </Alert>
                </Snackbar>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseError}>
                    <Alert onClose={handleCloseError} severity="success" sx={{ width: '100%' }} center>
                    La acción se ejecutó de manera correcta.
                    </Alert>
                </Snackbar>
            </div>			
        </div>
    );
}
 
export default Notification;