/** Reducer for List certificate request */

import { fromJS } from 'immutable';
import { SET_LIST_SCORE } from '../actions/scoreConst';

export const initialState = {
  listScore: []
};

const initialImmutableState = fromJS(initialState);

const score = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_LIST_SCORE:
      state = initialState;
      return state.listScore.concat(action.score);
    default:
      return state;
  }
};

export default score;
