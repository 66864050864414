

const subTitle = "Proximos Eventos";

const eventListTwo = [
    {
        date: '13-04-2023',
        title: 'Science Fair     Feria de Ciencias',
        time: '08:30 am',
        description: 'Feria de ciencias: DINOSAURS',
    },
    {
        date: '15-04-2023',
        title: 'Open House   Casa Abierta',
        time: '10:30 am',
        description: 'Act. extracurriculares: COCINA',
    },
    {
        date: '18-04-2023',
        title: 'Open House   Casa Abierta',
        time: '08:30 am',
        description: 'Act. extracurriculares: GIMNASIA',
    },
    {
        date: '25-04-2023',
        title: 'Open House   Casa Abierta',
        time: '10:00 am',
        description: 'Act. extracurriculares: FUTBOL',
    },
]

const Event = () => {
    return (
        <div className="event-section">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle">{subTitle}</span>
                </div>
                <div className="section-wrapper">
                    <div className="event-right">
                        {eventListTwo.map((val, i) => (
                            <div className="event-item" key={i}>
                                <div className="event-inner">
                                    <div className="event-content">
                                        <div className="event-date-info">
                                            <div className="edi-box">
                                                <h6>{val.date}</h6>
                                            </div>
                                        </div>
                                        <div className="event-content-info" center>
                                            <a href="#"><h5>{val.title}</h5></a> 
                                            {val.time}                                         
                                        </div>
                                        {val.description}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Event;