/** Reducer for List certificate request */

import { fromJS } from 'immutable';
import { SET_LIST_COURSE } from '../actions/courseConst';

export const initialState = {
  listCourse: []
};

const initialImmutableState = fromJS(initialState);

const course = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_LIST_COURSE:
      state = initialState;
      return state.listCourse.concat(action.course);
    default:
      return state;
  }
};

export default course;
