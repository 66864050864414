import GoogleLogin from 'react-google-login';


const responseGoogle=(response)=>{
    console.log(response);
}

const googleLogin = () => {
    return (       
        <GoogleLogin
            clientId="484579055032-1kgi8rdv2odvtoj98adlk769e01aiaob.apps.googleusercontent.com"
            //buttonText=""
            render={renderProps => (
                <button onClick={renderProps.onClick} disabled={renderProps.disabled}>This is my custom Google button</button>
              )}
            autoLoad={true}
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
        />
    );
}
 
export default googleLogin;