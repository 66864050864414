import { Fragment } from "react";
import { Link } from "react-router-dom";
import FacebookLogin from "../login/facebookLogin";
import GoogleLogin from "../login/googleLogin";
import Footer from "../../bootstrap/component/layout/footer-4";
import Header from "../../bootstrap/component/layout/header-5";



const title = "Register Now";
const socialTitle = "Register With Social Media";
const btnText = "Get Started Now";


let socialList = [
    {
        link: 'facebookLogin',
        iconName: 'icofont-facebook',
        className: 'facebook',
    },
    {
        link: 'googleLogin',
        iconName: 'icofont-twitter',
        className: 'twitter',
    },
    {
        link: '#',
        iconName: 'icofont-linkedin',
        className: 'linkedin',
    },
    {
        link: '#',
        iconName: 'icofont-instagram',
        className: 'instagram',
    },
]


const SignupPage = () => {
    return (
        <Fragment>
            <Header />
            <section className="banner-section">
            <div>
                <div className="section-wrapper">
                    <div className="login-section padding-tb section-bg">
                        <div className="container">
                            <div className="account-wrapper">
                                <h3 className="title">{title}</h3>
                                <form className="account-form">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="name"
                                            placeholder="User Name"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Email"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="password"
                                            placeholder="Password"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="password"
                                            placeholder="Confirm Password"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <button className="lab-btn"><span>{btnText}</span></button>
                                    </div>
                                </form>
                                <div className="account-bottom">
                                    <span className="d-block cate pt-10">Are you a member? <Link to="/login">Login</Link></span>
                                </div>
                            </div>
                        </div>
                    </div>
                 </div>
            </div>
        </section>
            <Footer />
        </Fragment>
    );
}
 
export default SignupPage;