import { Component, Fragment } from "react";
import Grid from '@material-ui/core/Grid';
import Footer from "../../bootstrap/component/layout/footer-4";
import Header from "../../bootstrap/component/layout/header-5.1";
import PageHeader from "../../bootstrap/component/layout/pageheader";
import Event from "../../bootstrap/component/section/event";
import PopularPost from "../../bootstrap/component/sidebar/popular-post";
import Instagram from "../../bootstrap/component/sidebar/instagram";
import Notification from "./Notification";

function PrincipalNot(props) {
    return (
        <Fragment>
            <Header />
            <Fragment>
            <PageHeader title={'Bienvenidos'} curPage={'Notificaciones'} />
            <div className="shop-page padding-tb">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-3 col-12">
                            <aside>                                
                            <Event />
                            </aside>
                        </div>
                        <div className="col-lg-6 col-12">
                            <article>
                                <Notification />
                            </article>
                        </div>
                        <div className="col-lg-3 col-12">
                            <aside>
                                <PopularPost />                              
                                <Instagram />
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
            <Footer />
        </Fragment>
    );
}
 
export default PrincipalNot;