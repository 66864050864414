import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import { getStorage } from '../../utils/authenticatedUser';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from './dialogs/List';


const title = "Administración";


function Administration (props) {

    const [student, setStudent] = useState([]);
    const [course, setCourse] = useState([]); 
    const [status, setStatus] = React.useState(false);    
    const [listTeacher, setListTeacher] = useState([]);
    const endpointTeach = 'http://127.0.0.1:8000/api';

     /**
  * Method to set the list pricing plans
  */
     const getAllActiveStudent = async() => {
        const $user = getStorage();
        const requestBody = {
            email: $user.email
          };

        await axios.post(endpointTeach+'/studentsByParent', requestBody).then((response) => {
            setStudent(response.data);
          })
          .catch((e) => {
            console.log(e);
          });
      };

      /**
  * Method to set the list pricing plans
  */
     const getAllActiveCourse = async() => {
        const response = await axios.get(endpointTeach+'/courses');
        setCourse(response.data);
      };

          /**
  * Method to set the list pricing plans
  */
     const setList = async() => {
        const requestBody = {
            course_id: 1,
            student_id: 3,
          };
      
        await axios.post(endpointTeach+'/findAllByCourse', requestBody).then((response) => {
            setListTeacher(response.data);
          })
          .catch((e) => {
            console.log(e);
          });
          return listTeacher;
      };

      /**
       * Retrieve pricing plan list
       */
      useEffect(() => {
        getAllActiveStudent();  
        getAllActiveCourse();
      }, []);

      const customList = () => (
        <div>
            {student.map((value) => {
                return(
                <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography>{value.name + " " + value.lastName}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <div style={{textAlign:"left"}}>
                 {course.map((cou, key)=>{
                    if(cou.id === value.course_id){
                        return(
                            <Button  id="insertTeacher" variant="contained" style={{backgroundColor: "#68dcb9", color: "#FFFFFF"}} onClick={setList} >{cou.name}</Button>
                        )
                    }
                 })}
                <br/>  <br/>
                <Button  id="insertTeacher" variant="contained" style={{backgroundColor: "#ffdf40", color: "#FFFFFF"}}  >Actividades</Button>
                <br/>  <br/>
                <Button  id="insertTeacher" variant="contained" style={{backgroundColor: "#68dcb9", color: "#FFFFFF"}}  >Calificaciones</Button>
                <br/>  <br/>
                </div>
                </AccordionDetails>
                </Accordion>
                );
            })}
        </div>
      );
    return (
        <div className="widget shop-widget">
            <div className="widget-header">
                <h5>{title}</h5>
            </div>
            <div className="widget-wrapper">
                {customList()}
            </div>
            <List openStatus={status} data={listTeacher}/>
        </div>
    );
}
 
export default Administration;