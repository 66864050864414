import FacebookLogin from 'react-facebook-login';

const componentClicked=()=>{
    console.log("login facebook");
}

const responseFacebook=(response)=>{
    console.log(response);
}

const facebookLogin = () => {
    return (
        <FacebookLogin
        textButton=""
        icon="fa-facebook"
        appId="1012115550165933"
        autoLoad={true}
        fields="name,email,picture"
        onClick={componentClicked}
        callback={responseFacebook} />
    );
}
 
export default facebookLogin;