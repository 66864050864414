import {Fragment, useState, useEffect, useRef } from "react";
import Event from "../../bootstrap/component/section/event";
import PopularPost from "../../bootstrap/component/sidebar/popular-post";
import dayjs from 'dayjs';
import Badge from '@mui/material/Badge';
import Administration from "./Administration"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, PickersDay, DateCalendar, DayCalendarSkeleton } from '@mui/x-date-pickers';
import styles from '../teacher/styles/teacher.module.css';

function fakeFetch(date, { signal }) {
    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        const daysToHighlight = [1, 12, 15, 27, 30];
  
        resolve({ daysToHighlight });
      }, 500);
  
      signal.onabort = () => {
        clearTimeout(timeout);
        reject(new DOMException('aborted', 'AbortError'));
      };
    });
  }
  
  const initialValue = dayjs(new Date());
  
  function ServerDay(props) {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
  
    const isSelected =
      !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;
  
    return (
      <Badge
        key={props.day.toString()}
        overlap="circular"
        badgeContent={isSelected ? '🌝' : undefined}
      >
        <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
      </Badge>
    );
  }
  

const Principal = () => {
    const requestAbortController = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [highlightedDays, setHighlightedDays] = useState([1, 2, 15]);
  
    const fetchHighlightedDays = (date) => {
      const controller = new AbortController();
      fakeFetch(date, {
        signal: controller.signal,
      })
        .then(({ daysToHighlight }) => {
          setHighlightedDays(daysToHighlight);
          setIsLoading(false);
        })
        .catch((error) => {
          if (error.name !== 'AbortError') {
            throw error;
          }
        });
  
      requestAbortController.current = controller;
    };
  
    useEffect(() => {
      fetchHighlightedDays(initialValue);
      return () => requestAbortController.current?.abort();
    }, []);
  
    const handleMonthChange = (date) => {
      if (requestAbortController.current) {
        requestAbortController.current.abort();
      }
  
      setIsLoading(true);
      setHighlightedDays([]);
      fetchHighlightedDays(date);
    };

    
    return (
        <Fragment>
            <div className="shop-page padding-tb">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-3 col-12">
                            <aside className={styles.scrollable}>                                
                            <Event />
                            </aside>
                        </div>
                        <div className="col-lg-6 col-12">
                            <article>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                <DateCalendar
                                    defaultValue={initialValue}
                                    loading={isLoading}
                                    style={{height:"300px"}}
                                    onMonthChange={handleMonthChange}
                                    renderLoading={() => <DayCalendarSkeleton />}
                                    slots={{
                                    day: ServerDay,
                                    }}
                                    slotProps={{
                                    day: {
                                        highlightedDays,
                                    },
                                    }}
                                />
                                </LocalizationProvider>
                            </article>
                        </div>
                        <div className="col-lg-3 col-12">
                            <aside className={styles.scrollable}>
                                <PopularPost /> 
                            </aside>
                        </div>
                    </div>
                 <Administration />
                </div>
            </div>
        </Fragment>
    );
}
 
export default Principal;