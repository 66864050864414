import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Courses from '../course/ShowCourse';
import CoursesAdd from '../course/FilterCourse';



/**
 * Function to present the filters and search the pricing plans by the filters
 * @param {*} props
 * @returns
 */
function PrincipalCourse(props) {
  
  return (
    <div>
    <CoursesAdd ></CoursesAdd>
    <Courses ></Courses>
    </div>
  );
}

PrincipalCourse.propTypes = {
  dataCourse: PropTypes.string,
};

PrincipalCourse.defaultProps = {
  dataCourse: "true"
};

export default PrincipalCourse