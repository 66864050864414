import { useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import logo from '../../../assets/images/creativeKids/logo.png';
import Button from '@material-ui/core/Button';
import { logout } from '../../../utils/logoutUser';


const HeaderFiveTeachers = () => {
    const [menuToggle, setMenuToggle] = useState(false);
	const [socialToggle, setSocialToggle] = useState(false);
	const [headerFiexd, setHeaderFiexd] = useState(false);
    const history = useHistory();

	window.addEventListener("scroll", () => {
		if (window.scrollY > 200) {
			setHeaderFiexd(true);
		} else {
			setHeaderFiexd(false);
		}
	});

    const handlePress = () => {
        logout(history);        
    };
    
    return (
        <header className={`header-section style-3 style-5 ${headerFiexd ? "header-fixed fadeInUp" : ""}`}>
            <div className={`header-top d-md-none ${socialToggle ? "open" : ""}`}>
                <div className="container">
                    <div className="header-top-area">
                        <Link to="/login">Log In</Link>
                    </div>
                </div>
            </div>
            <div className="header-bottom">
                <div className="container">
                    <div className="header-wrapper">
                        <div className="logo-search-acte">
                            <div className="logo">
                                <Link to="/"><img src={logo} alt="logo" width="200vh"/></Link>
                            </div>
                        </div>
                        <div className="menu-area">
                            <div className="menu">
                                <ul className={`lab-ul ${menuToggle ? "active" : ""}`}>
                                    <li className="menu-item-has-children">
                                        <a href="/publicTeachers" role="button" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Administración</a>
                                    </li>
                                </ul>
                            </div>
                            <Button  id="button_logout" className="lab-btn d-none d-md-block" onClick={handlePress} >Cerrar sesión</Button> 
                            
                            <div className={`header-bar d-lg-none ${menuToggle ? "active" : "" }`} onClick={() => setMenuToggle(!menuToggle)}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="ellepsis-bar d-md-none" onClick={() => setSocialToggle(!socialToggle)}>
                                <i className="icofont-info-square"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
 
export default HeaderFiveTeachers;